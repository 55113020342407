@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: 'League Spartan', Monaco, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loader-icon {
  display: inline-block;
  height: 6.4rem;
  width: 6.4rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: #ff9b00;
  position: relative;
  border-radius: 50%;
  margin-bottom: 2.5rem;
  -webkit-animation: rotate 0.3s linear infinite;
  animation: rotate 0.3s linear infinite;
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: inherit !important;
  padding: 8px;
}
.ql-container.ql-snow {
  border: none !important;
}

.css-1fdsijx-ValueContainer {
  height: 45px;
}

@media (max-width: 640px) {
  .stepper .steps {
    flex-direction: column;
  }
  .stepper .steps .step {
    margin-bottom: 1rem;
  }
}
